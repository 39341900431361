import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectMlData, getMlData, getLoadingState } from '../../redux/slices/mlSlice'; 
import { OrcaTable } from '../../Components/common/OrcaTable';
import store from '../../redux/store';
import { LdsModal, useLdsModal, LdsButton, LdsTextField, LdsSelect } from "@elilillyco/ux-lds-react";

const MetricsLibrary = () => {
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [ modalHeading, setModalHeading ] = useState('Update Insight Status Admin');
  const [ formData, setFormData ] = useState({});

  const data = (useSelector(selectMlData)).map(row => {
    let newRow = { ...row };
    newRow.br_insight_graph = "yes";
    newRow.capability = "br_insight";
    return newRow;
  });
  const loading = useSelector(getLoadingState);

  useEffect(() => {
    store.dispatch(getMlData());
  }, []);

  const actionButtons = [
    {
      iconName: 'EyeFill',
      iconLabel: 'View Metric Details',
      onClick: (row) => {
        let newrow = { ...row };
        setFormData(newrow);
        setModalHeading('Visual Graph Library');
        setIsModalOpen(true);
      },
      isVisible: (userRole, row) => true,
    },
  ];

  const fieldsValues = [
    { value: 'insight_graph_type', label: 'Graph Type' },
    { value: 'insight_graph_display_section', label: 'Graph Display Section' },
    { value: 'insight_graph_display_format', label: 'Graph Display Format' },
    { value: 'insight_graph_metric_to_display', label: 'Graph Metric to Display' },
    { value: 'insight_graph_market_basket_class_name', label: 'Graph Market Basket Class Name' },
    { value: 'insight_graph_entities_to_display', label: 'Graph Entities to Display' },
    { value: 'insight_graph_brand_indication', label: 'Graph Brand Indication' },
    { value: 'insight_graph_graph_title', label: 'Graph Title' },
    { value: 'insight_graph_time_based', label: 'Graph Time Based' },
    { value: 'insight_graph_x_label', label: 'Graph X Label' },
    { value: 'insight_graph_y_label', label: 'Graph Y Label' },
    { value: 'insight_graph_grain', label: 'Graph Grain' },
    { value: 'insight_graph_look_back', label: 'Graph Look Back' },
    { value: 'insight_graph_data_source', label: 'Graph Data Source' },
    { value: 'insight_graph_metric_title', label: 'Graph Metric Title' },
    { value: 'insight_graph_source_owner', label: 'Graph Source Owner' },
    { value: 'insight_graph_source_filename', label: 'Graph Source Filename' },
    { value: 'insight_graph_metric_key', label: 'Graph Metric Key' },
    { value: 'insight_graph_metric_name', label: 'Graph Metric Name' },
    { value: 'insight_graph_applicable_for_sleeve', label: 'Graph Applicable for Sleeve' },
    { value: 'brand', label: 'Brand' },
    { value: 'indication', label: 'Indication' },
    { value: 'status', label: 'Status' },
  ];

  return (
    <>
      <LdsModal
        modalId="viewMetricModal"
        open={isModalOpen}
        setModalOpen={setIsModalOpen}
        heading={modalHeading || "View Metric Details"}
        persistent
      >
        <LdsButton
          clearDefaultClasses
          className="lds-button-clear-style lds-modal-close-btn focus icon"
          onClick={() => setIsModalOpen(false)}
          icon="X"
          iconOnly
        />
        <div className='controls' style={{display:'grid', gridTemplateColumns:'500px 500px', justifyContent: 'space-between', alignItems:'center' }}>
        {fieldsValues.map((field, index) => (
          <LdsTextField key={index} label={field.label} value={formData[field.value]} disabled={true} style={{color: 'inherit', marginBottom:'10px', marginTop:'10px'}} />
        ))}
        </div>
      </LdsModal>
      <OrcaTable loading={loading} showFilters rows={data} actionButtons={actionButtons} cloneRequestModal title='Visual Graph Library' displayKey={{ 
'insight_graph_type': { 'display': true }, 'insight_graph_metric_key': { 'display': true }, 'insight_graph_display_section': { 'display': true }, 'insight_graph_display_format': { 'display': true }, 'insight_graph_metric_to_display': { 'display': true }, 'insight_graph_metric_name': { 'display': true }, "insight_graph_market_basket_class_name": { 'display': true }, 'insight_graph_entities_to_display': { 'display': true }, 'insight_graph_brand_indication': { 'display': true }, 'insight_graph_source_owner': { 'display': true }, 'insight_graph_source_filename': { 'display': true }, 'insight_graph_data_source': { 'display': true }, 'insight_graph_graph_title': { 'display': true }, 'insight_graph_time_based': { 'display': true }, 'insight_graph_x_label': { 'display': true }, 'insight_graph_y_label': { 'display': true }, 'insight_graph_grain': { 'display': true }, 'insight_graph_look_back': { 'display': true }, 'insight_graph_applicable_for_sleeve': { 'display': true }, 'status': { 'display': true } }} filterColumns={['insight_graph_type', 'insight_graph_display_section', 'insight_graph_display_format', 'insight_graph_metric_to_display', 'insight_graph_market_basket_class_name', 'insight_graph_entities_to_display', 'insight_graph_brand_indication','insight_graph_graph_title','insight_graph_time_based','insight_graph_x_label','insight_graph_y_label','insight_graph_grain','insight_graph_look_back','insight_graph_data_source','insight_graph_metric_title','insight_graph_source_owner','insight_graph_source_filename','insight_graph_metric_key','insight_graph_metric_name','insight_graph_applicable_for_sleeve']}>
      </OrcaTable>
    </>
  );
};

export default MetricsLibrary;

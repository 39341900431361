import React from 'react'
import { LdsTooltip } from '@elilillyco/ux-lds-react'
import { OrcaTooltip } from './OrcaTooltip'

export const OrcaFormLabelOverrides = {
    "requester": "Requester",
    "title": "Request Title",
    "description": "Request Description",
    "business_unit": "Business Unit",
    "business_rationale": "Business Rationale",
    "request_brand": "Brand",
    "requested_sprint": "Requested Sprint",
    "bv_category": "Request Category",
    "bv_priority": "BU/Brand Priority",
    "bv_urgency": "Urgency",
    "bv_impact": "Business Impact",
    "bv_calc_score": "Calculated Score",
    "effort_enhancement_request": "Enhancement Request",
    "effort_granular_request": "Granular Request",
    "effort_po_sfo": "PO - SFO",
    "effort_po_bia": "PO - BIA",
    "effort_dtt_sfo": "DTT - SFO",
    "effort_o2": "O2",
    "effort_oppmodels_bia": "Opportunity Model - BIA",
    "effort_omni_ml": "Omnichannel ML Model",
    "capability": "Primary Capability",
    "insight_key": "Insight Key",
    "segment": "HCP Segment",
    "business_rule": "Business Rule",
    "bia_metrics": "Metrics from BI&A team",
    "other_metrics": "Metrics from other files",
    "trigger_criteria": "Trigger Criteria",
    "metrics_file": "Do the metrics require ingestion of new files?",
    "new_metrics_ingest": "Do the suggestions require ingestion of new metrics?",
    "refresh_freq": "Refresh Frequency",
    "fcap": "FCAP #",
    "sales_force_team": "Sales Force Team",
    "brand": "Brand",
    "indication": "Indication",
    "ct_priority": "CT Priority",
    "bia_metric_based_on": "BI&A Metric Defined Based On",
    "bia_trigger_based_on": "BI&A Trigger Defined Based On",
    "cloud_tag": "Cloud Tag Text",
    "highlight_cloud_tag": "Highlight Cloud Tag",
    "insight_category": "Insight Category",
    "br_insight_type": "Insight Type",
    "br_text_type": "Text Type",
    "br_insight_text": "Insight Text",
    "br_suggestion_text": "Suggestion Text",
    "br_cloud_tag_1": "Cloud Tag 1",
    "br_cloud_tag_2": "Cloud Tag 2",
    "br_cloud_tag_3": "Cloud Tag 3",
    "ta_priority": "Timeliness Actionability (TA) Priority",
    "br_insight_graph": "Business Rule Visual Metric",
    "insight_graph_type": "Metric Type",
    "insight_graph_metric_key": "Visual Graph Identifier",
    "insight_graph_metric_name": "Metric Name",
    "insight_graph_display_section": "Display Section",
    "insight_graph_display_format": "Display Format",
    "insight_graph_metric_to_display": "Metric to Display",
    "insight_graph_market_basket_class_name": "Market Basket / Therapeutic Class Name",
    "insight_graph_entities_to_display": "Entities to Display for metric",
    "insight_graph_source_owner": "Source Owner",
    "insight_graph_source_filename": "Source File Name",
    "insight_graph_data_source": "Data Source",
    "insight_graph_brand": "Brand",
    "insight_graph_indication": "Indication",
    "insight_graph_graph_title": "Graph Title",
    "insight_graph_x_label": "X-Label",
    "insight_graph_y_label": "Y-Label",
    "insight_graph_grain": "Grain",
    "insight_graph_look_back": "Look Back",
    "insight_graph_applicable_for_sleeve": "Applicable for Sleeve",
    "cardnumber": "Card Number",
    'sprint': "Sprint",
    'start_date': "Implementation Start Date", 
    'mid_quarter_date': "Mid-Sprint Release Date", 
    'end_quarter_date': "End-Sprint Release Date",
}

export const OrcaFormTooltipOverrides = {

    "title": <OrcaTooltip name="title" text="Provide a title for your request in 5-6 words to make it easily identifiable." />,
    "description": <OrcaTooltip name="description" text="Provide a detailed description of the request." />,
    "business_unit": <OrcaTooltip name="business_unit" text="Select the BU for which the request is applicable." />,
    "business_rationale": <OrcaTooltip name="business_rationale" text="Please elaborate on 'why' the enhancement is being requested and the impact anticipated." />,
    "request_brand": <OrcaTooltip name="request_brand" text="Select the applicable brand(s)" />,
    "requested_sprint": <OrcaTooltip name="requested_sprint" text="Choose desired enhancement  sprint for implementation. Actual sprint implementation is subject to prioritization." />,
    
    "bv_category": <OrcaTooltip name="bv_category" text="Select the category of the enhancement request from the dropdown." />,
    "bv_priority": <OrcaTooltip name="bv_priority" text="Make a selection from the list provided." />,
    "bv_urgency": <OrcaTooltip name="bv_urgency" text="Make a selection from the list provided." />,
    "bv_impact": <OrcaTooltip name="bv_impact" text="Make a selection from the list provided." />,
    "bv_calc_score": <OrcaTooltip name="bv_calc_score" text="Auto-calculated &quot;business value score&quot; based on the above selections." />,
    
    "effort_enhancement_request": <OrcaTooltip name="effort_enhancement_request" text="Select the broad category of enhancement request type." />,
    "effort_granular_request": <OrcaTooltip name="effort_granular_request" text="Select the specific category of enhancement request type." />,   
    "capability": <OrcaTooltip name="capability" text="Choose the primary DT capability from the list. In case of BR or Cloud Tag Insights, you'll be required to fill additional details upon selection." />,

    "segment": <OrcaTooltip name="segment" text="If you want any target HCP to be eligible for this business rule, write 'target'. If you want certain segment of HCPs to be eligible, list those segments." />,
    "business_rule": <OrcaTooltip name="business_rule" text="Explain what event, pattern of behavior, or characteristic of HCP/patient/payer you want to flag and what you want as trigger condition. Example: No P2P attendance in last 180 days" />,
    "bia_metrics": <OrcaTooltip name="bia_metrics" text="List the metrics that need to be provided by BI&A in order to configure this business rule. Confirm these metrics with BI&A before submitting this request." />,
    "other_metrics": <OrcaTooltip name="other_metrics" text="List the metrics that are needed to configure the business rule, but cannot be provided by BI&A. Usually, these types of metrics come from Tech@Lilly team. Confirm these metrics with BI&A and Tech@Lilly before submitting this request." />,
    "trigger_criteria": <OrcaTooltip name="trigger_criteria" text="Write the detail of the trigger criteria including trigger thresholds and lookback period. Discuss with BI&A and brand team before submitting the request." />,
    "fcap": <OrcaTooltip name="fcap" text="If you do not have FCAP # for suggestion text yet, leave this blank at time of submission. After you get the FCAP #, come back to add this information." />,
    "ct_priority": <OrcaTooltip name="ct_priority" text="Within the brand, rank all cloud tag insights in order of importance. Most important cloud tag insight should have the value '1'." />,
    "bia_metric_based_on": <OrcaTooltip name="bia_metric_based_on" text="If the trigger condition is that value in trigger flag is 'true', then explain here what condition makes that value 'true'. Example: Trigger criteria: cloud_tag_trigger = 'TRUE', BIA metric defined based on: Product X NBRx &gt;1 in last 12 months" />,
    "bia_trigger_based_on": <OrcaTooltip name="bia_trigger_based_on" text="If the trigger condition is that value in trigger flag is 'true', then explain here what condition makes that value 'true'. Example: Trigger criteria: cloud_tag_trigger = 'TRUE', BIA trigger defined based on: Product X NBRx &gt;1 in last 12 months" />,
    "cloud_tag": <OrcaTooltip name="cloud_tag" text="This is what TMs will see in the app. Be concise." />,
    "br_insight_text": <OrcaTooltip name="br_insight_text" text="Briefly explain HCP/patient/payer event or patterns of behavior. Refer to DT Strategy Playbook to understand standardized format of writing this text." />,
    "br_suggestion_text": <OrcaTooltip name="br_suggestion_text" text="Using bullet points, list the recommended topics to discuss and recommended resource to utilize, related to what's written in insight text. Refer to DT Strategy Playbook to understand standardized format of writing this text." />,
    "br_cloud_tag_1": <OrcaTooltip name="br_cloud_tag_1" text="Optional. DT Strategy Team recommendation is not to use this (leave it blank)." />,
    "br_cloud_tag_2": <OrcaTooltip name="br_cloud_tag_2" text="Optional. DT Strategy Team recommendation is not to use this (leave it blank)." />,
    "br_cloud_tag_3": <OrcaTooltip name="br_cloud_tag_3" text="Optional. DT Strategy Team recommendation is not to use this (leave it blank)." />,
    "insight_graph_metric_to_display": <OrcaTooltip name="insight_graph_metric_to_display" text={<>Briefly explain what you want to present with graph. <br/>[Example]<br/>Product X Market NBRx Trendline  - Monthly</>} />,
    "insight_graph_entities_to_display": <OrcaTooltip name="insight_graph_entities_to_display" text={<>Only applicable for multi-line graph. Write down what these graphs are representing.<br/>[Example]<br/>Products</>} />,
    "insight_graph_graph_title": <OrcaTooltip name="insight_graph_graph_title" text="This will be the title of the graph that TMs will see on the screen." />,
    "insight_graph_grain": <OrcaTooltip name="insight_graph_grain" text={<>[Example]<br/>Month</>} />,
    "insight_graph_look_back": <OrcaTooltip name="insight_graph_look_back" text={<># of data points to show<br/>[Example]<br/>12<br/>Month</>} />,
    "insight_graph_type": <OrcaTooltip name="insight_graph_type" text={<>&quot;New&quot; if you are requesting a new graph. <br/>&quot;Existing&quot; if you're reusing an existing graph</>} />,
    "insight_graph_metric_key": <OrcaTooltip name="insight_graph_metric_key" text={<>(Concatenation of Brand, Metric Name & Grain)<br/> Example: TALTZ_FM_NBRX_R13_W</>} />,
    "insight_graph_metric_name": <OrcaTooltip name="insight_graph_metric_name" text="Confirm with BI&A partner.  This should be the exact column name within source file" />,
    "insight_graph_market_basket_class_name": <OrcaTooltip name="insight_graph_market_basket_class_name" text={<>Example 1:<br/>Full Molecul<br/>Example 2:<br/>Psoriasis market</>} />,
    "insight_graph_source_owner": <OrcaTooltip name="insight_graph_source_owner" text="Discuss with BI&A partner if their team will be providing the source file, or if it will be Tech@Lilly" />,
    "insight_graph_source_filename": <OrcaTooltip name="insight_graph_source_filename" text={<>Example:<br/>US_HCP_SALES_METRICS_&lt;YYYYMMDD&gt;.txt</>} />,
    "insight_graph_data_source": <OrcaTooltip name="insight_graph_data_source" text={<>Example:<br/>IRIS RX Fact</>} />,

    "enhancement_request": <OrcaTooltip name="enhancement_request" text="Details of the enahancement request that is to be implemented in the upcoming sprint(s)." />,
    "br_insight_type": <OrcaTooltip name="br_insight_type" text="To select 'New' or 'Existing' from the drop-down menu to signify New Suggestion configuration or Updates to Existing suggestions respectively." />,
    "brand": <OrcaTooltip name="brand" text="The product for which the suggestion will be enabled." />,
    "indication": <OrcaTooltip name="indication" text="The product indication for which the suggestion will be enabled." />,
    "br_insight_category": <OrcaTooltip name="br_insight_category" text="Refers to the category of suggestions." />,
    "ta_priority": <OrcaTooltip name="ta_priority" text="Suggestion category priority" />,
    "refresh_freq": <OrcaTooltip name="refresh_freq" text="Refresh frequency of the suggestions" />,
    "sales_force_team": <OrcaTooltip name="sales_force_team" text="Column listing the salesforce teams for which the insight is applicable" />,
}
